footer{
    width: 100%;
    height: 100%;
    background: rgba(26,26,26,0.7);
}

footer .footer-continer{
    width: 90%;
    margin: auto;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit , minmax(250px , 1fr));
    grid-column-gap:1rem;
    grid-row-gap:1rem;
    padding: 0px 22px;
}

.column{
    font-family: 'Rajdhani', sans-serif;
    padding: 102px 32px;
}

.column ul{
    margin-top: 30px;
}

.column ul li{
   list-style: none;
   margin: 12px 0px;
}

.column ul li a{
    color: #9F9E9E;
    text-decoration: none;
    color: #9F9E9E;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    cursor: pointer;
    transition: all 0.3s linear;
}

.column ul li a:hover{
    color: #DF0E03;
    transition: all 0.3s linear;
}

.column p{
    color: #9F9E9E;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 4px 0px;
}

.column p a{
    color: #DF0E03;
    text-decoration: none;
}

.column .para{
    margin-top: 30px;
}

.column h1{
    color: #fff;
}

.follow ul{
    display: flex;
    gap: 1rem;
}

.follow ul li{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #DF0E03;
}

.follow ul li a{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.follow ul li a svg{
    font-weight: 600;
    color: #fff;
    font-size: 23px;
}

.copyright{
    background-color: rgba(42,38,32,0.5);
    padding: 24px 0px;
}

.copyright p{
    color: #fff;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;
    padding: 0px 12px;
}

.copyright p a{

    color: #DF0E03;
}

@media screen and (min-width:200px) and (max-width:1300px){
    .column{
        padding: 12px 22px;
    }
    footer .footer-continer{
        padding: 52px 0px;
    }
}

@media screen and (min-width:200px) and (max-width:400px){
    .copyright p{
        font-size: 15px;
    }
}

